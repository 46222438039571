import React from 'react';
import PropTypes from 'prop-types';
import { ShoppingCartPreview } from '@fingo/lib/components/banners';

const InvoicesSummary = ({ open }) => (
  <>{open && <ShoppingCartPreview cartType="available-factoring" />}</>
);

InvoicesSummary.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default InvoicesSummary;
