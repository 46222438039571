/* eslint-disable operator-linebreak */
import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useIsMobile, useSelectedCompany } from '@fingo/lib/hooks';
import { useQuery } from '@apollo/client';
import { GET_INVOICE_PROVIDER } from '@fingo/lib/graphql';
import Skeleton from '@mui/material/Skeleton';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { ELECTRONIC_INVOICES_TYPE } from '@fingo/lib/constants';
import {
  CredentialProviderSelector,
  CredentialProviderAuthMethodSelector,
  CredentialProviderAuthForm,
} from './credential-provider-dialog';
import { getCurrentCredentialProvider } from '../../helpers/credentials';
import {
  ELECTRONIC_INVOICES_DIALOG_ICON,
  ELECTRONIC_INVOICES_DIALOG_TITLE,
  ELECTRONIC_INVOICES_DIALOG_SUBTITLE,
} from '../../constants/credentials';

const CredentialProviderDialog = ({ open, setOpen }) => {
  const isMobile = useIsMobile();
  const selectedCompany = useSelectedCompany();
  const [selectedProvider, setSelectedProvider] = useState();
  const [authenticationType, setAuthenticationType] = useState();

  const providerClickHandler = useCallback((newProvider) => {
    setSelectedProvider(newProvider);
    if (newProvider.name !== 'MiPyme') {
      setAuthenticationType('username');
    }
  }, []);

  const authMethodClickHandler = useCallback((authMethod) => {
    setAuthenticationType(authMethod);
  }, []);

  const cancelHandler = useCallback(() => {
    setSelectedProvider(null);
    setAuthenticationType(null);
  }, []);

  const { data, loading } = useQuery(GET_INVOICE_PROVIDER, {
    variables: { credentialProviderType_Name: ELECTRONIC_INVOICES_TYPE },
  });

  const providers = useMemo(() => Object.values(data || [])?.[0] || [], [data]);
  const integratedProviders = useMemo(
    () => providers.filter(({ integrated }) => integrated),
    [providers],
  );
  const notIntegratedProviders = useMemo(
    () => providers.filter(({ integrated }) => !integrated),
    [providers],
  );
  const currentProvider = useMemo(
    () => getCurrentCredentialProvider(selectedCompany),
    [selectedCompany],
  );

  if (loading) return <Skeleton />;

  const showCredentialProviderAuthMethodSelector =
    selectedProvider?.name === 'MiPyme' && !authenticationType;
  const showCredentialProviderAuthForm = selectedProvider && authenticationType;

  return (
    <FingoDialog
      open={open}
      handleClose={() => {
        cancelHandler();
        setOpen(false);
      }}
      maxWidth="sm"
      fullWidth
      title={ELECTRONIC_INVOICES_DIALOG_TITLE}
      subtitle={!isMobile ? ELECTRONIC_INVOICES_DIALOG_SUBTITLE : null}
      icon={!isMobile ? ELECTRONIC_INVOICES_DIALOG_ICON : null}
    >
      {!selectedProvider && (
        <CredentialProviderSelector
          integratedProviders={integratedProviders}
          notIntegratedProviders={notIntegratedProviders}
          currentProvider={currentProvider}
          onProviderClickHandler={providerClickHandler}
          buttonLabel="Otro facturador"
          listSubheaderLabel="Facturador"
          showHelpLink
        />
      )}
      {showCredentialProviderAuthMethodSelector && (
        <CredentialProviderAuthMethodSelector
          selectedProvider={selectedProvider}
          onCancelHandler={cancelHandler}
          onClickHandler={authMethodClickHandler}
        />
      )}
      {showCredentialProviderAuthForm && (
        <CredentialProviderAuthForm
          selectedProvider={selectedProvider}
          authenticationType={authenticationType}
          onCancelHandler={cancelHandler}
        />
      )}
    </FingoDialog>
  );
};

CredentialProviderDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default CredentialProviderDialog;
