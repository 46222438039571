import React from 'react';
import PropTypes from 'prop-types';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { useIsMobile } from '@fingo/lib/hooks';
import {
  DIGITAL_CERTIFICATE_DIALOG_TITLE,
  DIGITAL_CERTIFICATE_DIALOG_SUBTITLE,
  DIGITAL_CERTIFICATE_DIALOG_ICON,
} from '../../constants/credentials';
import UploadDigitalCertificateForm from './upload-digital-certificate-dialog/UploadDigitalCertificateForm';

const UploadDigitalCertificateDialog = ({ open, setOpen }) => {
  const isMobile = useIsMobile();
  return (
    <FingoDialog
      open={open}
      handleClose={() => setOpen(false)}
      maxWidth="sm"
      fullWidth
      title={DIGITAL_CERTIFICATE_DIALOG_TITLE}
      subtitle={!isMobile ? DIGITAL_CERTIFICATE_DIALOG_SUBTITLE : null}
      icon={!isMobile ? DIGITAL_CERTIFICATE_DIALOG_ICON : null}
    >
      <UploadDigitalCertificateForm />
    </FingoDialog>
  );
};

UploadDigitalCertificateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default UploadDigitalCertificateDialog;
