import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import Screens from '../../assets/images/screens.png';

const Onboarding = ({ goNextStep }) => (
  <>
    <Grid container px={5}>
      <Grid item xs={6} md={5} my={5}>
        <Stack spacing={5}>
          <Typography variant="h0" color="primary">
            ¡Financia tus facturas con Fingo!
          </Typography>
          <Typography variant="h3">
            <strong>
              Sube tu información financiera
            </strong>{' '}
            automáticamente por plataforma
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              goNextStep();
            }}
            sx={{ borderRadius: 3, width: 200 }}
            id="onboarding-continue"
          >
            Continuar <KeyboardArrowRight />
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={6} md={7} sx={{ marginY: 'auto', marginLeft: 'auto' }} width="100%">
        <Box
          component="img"
          src={Screens}
          alt="Devices"
          sx={{
            width: '100%',
            display: 'block',
          }}
        />
      </Grid>
    </Grid>
  </>
);

Onboarding.propTypes = {
  goNextStep: PropTypes.func.isRequired,
};

export default Onboarding;
