import { useReactiveVar } from '@apollo/client';
import { shoppingCartDialogVar } from '@fingo/lib/apollo/reactive-variables/shopping-cart';
import { StepperDrawer } from '@fingo/lib/components/drawer';
import { closeShoppingCart } from '@fingo/lib/helpers';
import React from 'react';
import useCartStepsHookProvider from '../../hooks/offer-shopping-cart/useCartStepsHookProvider';

const OffersShoppingCart = () => {
  const { isOpen } = useReactiveVar(shoppingCartDialogVar);
  const { offerShoppingSteps, currentStep } = useCartStepsHookProvider();

  if (!isOpen || !offerShoppingSteps) return null;

  return (
    <StepperDrawer
      open={isOpen}
      onClose={closeShoppingCart}
      steps={offerShoppingSteps}
      currentStep={currentStep}
      PaperProps={{
        sx: { width: '40%' },
      }}
    />
  );
};

export default OffersShoppingCart;
