export const TABS = [
  {
    id: 'invoices',
    label: 'Financiamiento de Facturas',
    value: '/invoices',
    to: '/invoices',
  },
  { id: 'offers', label: 'Facturas aprobadas', value: '/offers', to: '/offers' },
  {
    id: 'cessions',
    label: 'Estado de operaciones',
    value: '/cessions',
    to: '/cessions',
  },
  {
    id: 'credentials',
    label: 'Mis credenciales',
    value: '/credentials',
    to: '/credentials',
  },
];

export const validPaths = TABS.map((tab) => tab.value);
