import React from 'react';
import Grid from '@mui/material/Grid';
import CessionsSummaryCards from './CessionsSummaryCards';
import CessionsSummaryActions from './CessionsSummaryActions';

const CessionsSummary = () => (
  <Grid
    direction="row"
    container
    spacing={2}
    sx={{
      bgcolor: 'background.light',
      borderRadius: 2,
      p: 1,
    }}
  >
    <Grid item xs={9}>
      <CessionsSummaryCards />
    </Grid>
    <Grid item xs={3}>
      <CessionsSummaryActions />
    </Grid>
  </Grid>
);

export default CessionsSummary;
