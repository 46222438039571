import React, { useMemo } from 'react';
import MuiTabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link, useLocation } from 'react-router-dom';
import { TABS, validPaths } from '../../constants/layout';

const Tabs = () => {
  const { pathname } = useLocation();
  const showTabs = useMemo(() => validPaths.includes(pathname), [pathname]);

  return (
    <MuiTabs value={pathname}>
      {showTabs
        && TABS.map(({ id, label, value, to }) => (
          <Tab key={id} label={label} value={value} to={to} component={Link} />
        ))}
    </MuiTabs>
  );
};

export default Tabs;
