import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useIsMobile } from '@fingo/lib/hooks';
import NationalTaxServiceLogoAndText from '@fingo/lib/components/text/NationalTaxServiceLogoAndText';
import NationalTaxServiceCredentialsForm from '@fingo/lib/components/forms/NationalTaxServiceCredentialsForm';

const NationalTaxServiceCredentialsDialog = ({ open, setOpen, source, onCompleted, onError }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  if (!open) return null;

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: { borderRadius: 20 },
      }}
    >
      <Card
        width="100%"
        sx={(theme) => ({
          px: 4,
          background: {
            xs: 'white',
            md: `linear-gradient(295deg, ${theme.palette.primary.main} 57%, white 57%)`,
          },
          borderRadius: 20,
          overflow: { xs: 'scroll', md: 'hidden' },
        })}
        elevation={4}
      >
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          alignItems="center"
          sx={{ py: 4, px: { xs: 6, md: 0 } }}
          width="100%"
          spacing={3}
        >
          {isMobile && (
            <Typography variant="h5" align="center" color="text.main" sx={{ px: 6 }} fontWeight={600}>
              Ingresa las credenciales del <br />{' '}
              <strong>{t('National Tax Service credentials')}</strong> de tu empresa
            </Typography>
          )}
          <NationalTaxServiceLogoAndText width={isMobile ? '100%' : '36%'} />
          <NationalTaxServiceCredentialsForm
            source={source}
            onCompleted={() => { onCompleted(); setOpen(false); }}
            onError={() => { onError(); setOpen(false); }}
          />
        </Stack>
      </Card>
    </Dialog>
  );
};

NationalTaxServiceCredentialsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  source: PropTypes.string,
  onCompleted: PropTypes.func,
  onError: PropTypes.func,
};

NationalTaxServiceCredentialsDialog.defaultProps = {
  source: null,
  onCompleted: () => {},
  onError: () => {},
};

export default NationalTaxServiceCredentialsDialog;
