import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TODAY, TWO_MONTHS_AGO } from '@fingo/lib/constants';

import { formatMinWordDate } from '@fingo/lib/helpers';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import DatesFilter from '@fingo/lib/components/filters/DatesFilter';
import FilterButton from '@fingo/lib/components/buttons/FilterButton';

const DatesFilterSelector = ({ onSubmit }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dates, setDates] = useState({
    dateIssued_Gte: TWO_MONTHS_AGO,
    dateIssued_Lte: TODAY,
  });

  const handleClickButton = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeDateFilter = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <FilterButton
        variant="filter"
        color="text"
        size="small"
        endIcon={<ExpandMore />}
        onClick={handleClickButton}
        id="dates-filter"
      >
        <Typography variant="filter">
          {dates
          && `${formatMinWordDate(dates.dateIssued_Gte)} - ${formatMinWordDate(dates.dateIssued_Lte)}`}
        </Typography>
      </FilterButton>
      <DatesFilter
        anchorEl={anchorEl}
        close={closeDateFilter}
        dates={dates}
        setDates={setDates}
        setStates={onSubmit}
      />
    </>
  );
};

DatesFilterSelector.propTypes = {

  onSubmit: PropTypes.func.isRequired,
};

export default DatesFilterSelector;
