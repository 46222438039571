/* eslint-disable operator-linebreak */

import BadgeOutlined from '@mui/icons-material/BadgeOutlined';
import Business from '@mui/icons-material/Business';
import AccountBalanceSharp from '@mui/icons-material/AccountBalanceSharp';
import TextSnippetOutlined from '@mui/icons-material/TextSnippetOutlined';

export const ELECTRONIC_INVOICES_DIALOG_TITLE = 'Ingresa tus credenciales del facturador';

export const ELECTRONIC_INVOICES_DIALOG_SUBTITLE =
  'Elige tu facturador y sube tus credenciales para realizar tus cesiones por dentro de la plataforma, y que así no tengas que salir de Fingo.';

export const ELECTRONIC_INVOICES_DIALOG_ICON = BadgeOutlined;

export const SUPPLIER_PLATFORM_DIALOG_TITLE = 'Ingresa tus credenciales del portal de proveedores';

export const SUPPLIER_PLATFORM_DIALOG_SUBTITLE =
  'Sube las credenciales de tus portales de proveedores para obtener el estado de pago de tus facturas al día y descubre todas las nuevas y útiles funcionalidades que tenemos para ti.';

export const SUPPLIER_PLATFORM_DIALOG_ICON = Business;

export const BANK_ACCOUNTS_DIALOG_TITLE = 'Administra tus cuentas bancarias';

export const BANK_ACCOUNTS_DIALOG_SUBTITLE = `Ingresa tus datos bancarios para que podamos depositarte. Puedes 
      ingresar múltiples cuentas y seleccionar la que más te acomode al momento de girar.`;

export const BANK_ACCOUNTS_DIALOG_ICON = AccountBalanceSharp;

export const DIGITAL_CERTIFICATE_DIALOG_TITLE = 'Administra tu certificado digital';

export const DIGITAL_CERTIFICATE_DIALOG_SUBTITLE = `Carga aquí el certificado digital del Representante Legal de tu 
      empresa para que puedas ceder facturas y contar con más seguridad en las operaciones.`;

export const DIGITAL_CERTIFICATE_DIALOG_ICON = TextSnippetOutlined;

export const DIGITAL_CRETIFICATE_INITIAL_VALUE = {
  password: '',
  file: null,
};
