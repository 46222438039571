import React from 'react';
import { AmountWithIvaCell, CompanyCell } from '@fingo/lib/components/cells';
import DateCell from '@fingo/lib/components/cells/DateCell';
import { formatDecimal } from '../helpers/formatters';
import STATUS_TO_SPANISH from './status';

export const TITLE = 'Estado de cesiones';

export const COLUMNS = [
  {
    field: 'folio',
    headerName: 'Folio',
    flex: 1,
  },
  {
    field: 'dateIssued',
    headerName: 'Emisión',
    flex: 1,
    renderCell: ({ row }) => <DateCell date={row.dateIssued} />,
  },
  {
    field: 'receiver_Name',
    headerName: 'Receptor',
    flex: 2,
    renderCell: ({ row }) => <CompanyCell company={row.receiver} />,
  },
  {
    field: 'amountWithIva',
    headerName: 'Monto',
    flex: 1,
    renderCell: ({ row }) => <AmountWithIvaCell row={row} />,
  },
  {
    field: 'offer_MonthlyRate',
    headerName: 'Tasa',
    flex: 1,
    valueGetter: ({ row }) => formatDecimal(row.offer?.monthlyRate),
  },
  {
    field: 'offer_DefaultRate',
    headerName: 'Mora',
    flex: 1,
    valueGetter: ({ row }) => formatDecimal(row.offer?.defaultRate),
  },
  {
    field: 'operationDateToPay',
    headerName: 'Fecha de pago',
    flex: 1,
    renderCell: ({ row }) => <DateCell date={row.dateToPay} />,
  },
  {
    field: 'cessionStatus',
    headerName: 'Estado',
    flex: 2,
    valueGetter: ({ row }) => STATUS_TO_SPANISH[row.status],
  },
];
