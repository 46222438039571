import React from 'react';
import { GRID_DATE_COL_DEF } from '@mui/x-data-grid';
import { AmountWithIvaCell, CompanyCell } from '@fingo/lib/components/cells';
import DateCell from '@fingo/lib/components/cells/DateCell';
import { DateToPayEditCell } from '@fingo/lib/components/dataGridCells';
import { formatDecimal } from '../helpers/formatters';

export const TITLE = 'Ofertas';
export const SUBTITLE = '';
export const COLUMNS = [
  {
    field: 'folio',
    headerName: 'Folio',
  },
  {
    field: 'dteType_Code',
    headerName: 'Tipo',
    valueGetter: ({ row }) => row.dteType.name,
  },
  {
    field: 'dateIssued',
    headerName: 'Emisión',
    renderCell: ({ row }) => <DateCell date={row.dateIssued} />,
  },
  {
    field: 'receiver_Name',
    headerName: 'Receptor',
    flex: 1,
    renderCell: ({ row }) => <CompanyCell company={row.receiver} />,
  },
  {
    field: 'amountWithIva',
    headerName: 'Monto',
    renderCell: ({ row }) => <AmountWithIvaCell row={row} />,
  },
  {
    field: 'offer_MonthlyRate',
    headerName: 'Tasa',
    valueGetter: ({ row }) => formatDecimal(row.offer.monthlyRate),
  },
  {
    field: 'offer_DefaultRate',
    headerName: 'Mora',
    valueGetter: ({ row }) => formatDecimal(row.offer.defaultRate),
  },
  {
    field: 'dateToPay',
    headerName: 'Fecha de pago',
    ...GRID_DATE_COL_DEF,
    resizable: false,
    minWidth: 175,
    renderCell: (params) => (<DateToPayEditCell params={params} />),
  },
];
