import { useReactiveVar } from '@apollo/client';
import { shoppingCartDialogVar } from '@fingo/lib/apollo/reactive-variables/shopping-cart';
import { StepperDrawer } from '@fingo/lib/components/drawer';
import { closeShoppingCart } from '@fingo/lib/helpers';
import React from 'react';
import usePreofferShoppingCartSteps from '../../hooks/usePreofferShoppingCartSteps';

const InvoicesShoppingCart = () => {
  const { isOpen } = useReactiveVar(shoppingCartDialogVar);
  const {
    preofferShoppingSteps,
    currentStep,
  } = usePreofferShoppingCartSteps();
  if (!isOpen) return null; /* so it does not render the component */
  return (
    <StepperDrawer
      open={isOpen}
      onClose={closeShoppingCart}
      steps={preofferShoppingSteps}
      currentStep={currentStep}
      PaperProps={{
        sx: { width: '40%' },
      }}
    />
  );
};

export default InvoicesShoppingCart;
