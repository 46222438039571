import React from 'react';
import PropTypes from 'prop-types';
import { ShoppingCartPreview } from '@fingo/lib/components/banners';

const OffersSummary = ({ open }) => (
  <>{open && <ShoppingCartPreview cartType="available-offers" />}</>
);

OffersSummary.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default OffersSummary;
