import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { shoppingCartDialogVar } from '@fingo/lib/apollo/reactive-variables/shopping-cart';
import { PREOFFERS, OFFERS } from '@fingo/lib/constants/shopping-carts';
import OffersShoppingCart from './OffersShoppingCart';
import PreofferShoppingCart from './PreofferShoppingCart';

const ShoppingCart = () => {
  const { type } = useReactiveVar(shoppingCartDialogVar);
  if (type === null) return null;
  return (
    <>
      {type === PREOFFERS && <PreofferShoppingCart />}
      {type === OFFERS && <OffersShoppingCart />}
    </>
  );
};

export default ShoppingCart;
