import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { useSelectedCompany } from '@fingo/lib/hooks';
import { CESSIONS_SUMMARY } from '@fingo/lib/graphql';
import DownloadCessionsSummaryDialog from '@fingo/lib/components/dialogs/DownloadCessionsSummaryDialog';
import SurplusWithdrawDialog from '../dialogs/SurplusWithdrawDialog';

const CessionsSummaryActions = () => {
  const [openSurplusWithdrawDialog, setOpenSurplusWithdrawDialog] = useState(false);
  const [
    openDownloadCessionSummaryDialog,
    setOpenDownloadCessionSummaryDialog,
  ] = useState(false);
  const selectedCompany = useSelectedCompany();

  const { data } = useQuery(CESSIONS_SUMMARY, {
    variables: { companyId: selectedCompany?.id },
  });

  const { availableSurplus, debtAmount } = useMemo(
    () => data?.cessionsSummary || {},
    [data],
  );
  const hasAvailableSurplus = useMemo(
    () => availableSurplus?.amount > 0,
    [availableSurplus],
  );

  const canWithdrawSurplus = useMemo(
    () => debtAmount?.amount <= 0
      && availableSurplus?.amount > 0
      && selectedCompany?.pendingReintegrations?.count <= 0,
    [debtAmount, availableSurplus, selectedCompany],
  );

  return (
    <Box width="100%">
      <Stack width="100%">
        <Button
          color="primary"
          variant="contained"
          width="100%"
          size="small"
          disableElevation
          onClick={() => setOpenDownloadCessionSummaryDialog(true)}
          id="toggle-download-portfolio"
        >
          Descargar resumen
        </Button>
        <Tooltip
          title={
            !hasAvailableSurplus
              ? 'No tienes excedentes disponible para retirar'
              : null
          }
        >
          <Button
            color="primary"
            variant="containedReverse"
            disableElevation
            size="small"
            width="100%"
            fontSize="0.8rem"
            sx={{ mt: 0.5 }}
            onClick={() => setOpenSurplusWithdrawDialog(true)}
            disabled={!hasAvailableSurplus}
            id="withdraw-surplus"
          >
            {canWithdrawSurplus
              ? 'Retirar excedentes'
              : '¿Por qué no puedo retirar excedentes?'}
          </Button>
        </Tooltip>
      </Stack>
      <DownloadCessionsSummaryDialog
        open={openDownloadCessionSummaryDialog}
        onClose={() => setOpenDownloadCessionSummaryDialog(false)}
        product="FACTORING"
      />
      <SurplusWithdrawDialog
        open={openSurplusWithdrawDialog}
        canWithdraw={canWithdrawSurplus}
        onClose={() => setOpenSurplusWithdrawDialog(false)}
      />
    </Box>
  );
};

export default CessionsSummaryActions;
