import React from 'react';
import PropTypes from 'prop-types';
import { historyToFilters } from '@fingo/lib/helpers/url-filter-translator';
import { useHistory } from 'react-router-dom';
import InvoiceStatesFilter from './InvoiceStatesFilter';
import DatesFilterSelector from './DatesFilterSelector';

const InvoicesActions = ({ setActionsFilters }) => {
  const history = useHistory();
  const submitHandler = (newFilters) => {
    setActionsFilters((prevState) => {
      const filters = { ...prevState, ...newFilters };
      const filteredNewFilters = Object.fromEntries(
        Object.entries(filters).filter(([, value]) => value !== undefined),
      );
      historyToFilters(history, filteredNewFilters);
      return filteredNewFilters;
    });
  };
  return (
    <>
      <InvoiceStatesFilter onSubmit={submitHandler} />
      <DatesFilterSelector onSubmit={submitHandler} />
    </>
  );
};

InvoicesActions.propTypes = {
  setActionsFilters: PropTypes.func.isRequired,
};

export default InvoicesActions;
