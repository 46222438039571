import React from 'react';
import {
  AmountWithIvaCell,
  CompanyCell,
  FactoringStatusCell,
} from '@fingo/lib/components/cells';
import DateCell from '@fingo/lib/components/cells/DateCell';
import { dteTypeToLabel } from '@fingo/lib/helpers';
import { formatDecimal } from '../helpers/formatters';

export const TITLE = 'Financiamiento de facturas';
export const SUBTITLE = '';
export const COLUMNS = [
  {
    field: 'folio',
    headerName: 'Folio',
  },
  {
    field: 'dteType_Code',
    headerName: 'Tipo',
    valueGetter: ({ row }) => row.dteType.name,
  },
  {
    field: 'dateIssued',
    headerName: 'Emisión',
    flex: 1,
    renderCell: ({ row }) => <DateCell date={row.dateIssued} />,
  },
  {
    field: 'receiver_Name',
    headerName: 'Receptor',
    flex: 2,
    renderCell: ({ row }) => <CompanyCell company={row.receiver} />,
  },
  {
    field: 'amountWithIva',
    headerName: 'Monto',
    flex: 1,
    renderCell: ({ row }) => <AmountWithIvaCell row={row} />,
  },
  {
    field: 'preoffer_MonthlyRate',
    headerName: 'Tasa',
    flex: 1,
    valueGetter: ({ row }) => formatDecimal(row.preoffer?.monthlyRate),
  },
  {
    field: 'preoffer_DefaultRate',
    headerName: 'Mora',
    flex: 1,
    valueGetter: ({ row }) => formatDecimal(row.preoffer?.defaultRate),
  },
  {
    field: 'factoringStatus',
    headerName: 'Estado',
    renderCell: ({ row }) => <FactoringStatusCell row={row} />,
    flex: 2,
  },
];

export const FINANCING_STATES_OPTIONS = [
  { label: 'Disponibles', value: 'AVAILABLE' },
  { label: 'No disponibles', value: 'UNAVAILABLE' },
];

export const LOAN_STATES_OPTIONS = [
  { label: 'Cedidas', value: 'Loaned' },
  { label: 'No cedidas', value: 'Available' },
];

export const SII_STATES_OPTIONS = [
  { label: 'Con acuse de recibo', value: 'Reception acknowledged' },
  { label: 'Rechazadas', value: 'Rejected' },
  { label: 'Sin acciones en el Sii', value: 'Valid' },
];

export const CREDIT_NOTE_STATES_OPTIONS = [
  { label: 'Con nota de crédito total', value: 'Has credit note' },
  { label: 'Con nota de crédito parcial', value: 'Partial credit note' },
  { label: 'Sin nota de crédito', value: 'Valid' },
];

export const DOCUMENT_TYPE_OPTIONS = [33, 34, 56, 61].map((dteType) => ({
  label: dteTypeToLabel[dteType],
  value: dteType,
}));

export const INTEGRATION_STATES_OPTIONS = [
  { label: 'Rechazada', value: '1' },
  { label: 'Bloqueada en contabilidad', value: '2' },
  { label: 'Bloqueada en pagos', value: '3' },
  { label: 'Contabilizada', value: '4' },
  { label: 'Enviada para pago', value: '5' },
  { label: 'Pagada parcialmente', value: '6' },
  { label: 'Pagada completamente', value: '7' },
  { label: 'En proceso', value: '8' },
];

export const INITIAL_INVOICE_STATES = {
  siiStatus: undefined,
  dteType_Code_In: undefined,
  loanedStatus: undefined,
  creditNoteStatus: undefined,
  documentFinanceStatus: undefined,
  availableForFinancing: undefined,
  documentfinancestate_PaymentDate_Gte: undefined,
  documentfinancestate_PaymentDate_Lte: undefined,
  allCompanies: undefined,
};
